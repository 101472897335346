.navbar .collapsing .navbar-toggler{
  pointer-events: none;
}
.navbar .nav-item .nav-link-icon.nav-link i{
  margin-right: 4px;
}


.navbar-nav .nav-link {
  color: $gray-800 !important;
}

.navbar-nav .nav-link:hover {
  color: $primary !important;
}

.nav-item > .active {
  color: $primary !important;
}

