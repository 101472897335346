.post_error_loading {
  padding-top: 7rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

/* .modal-dialog-centered {
  height: 50%;
} */

@media (min-width: 1600px) {
  .registered br {
    display: none;
  }
}
.popup-border-left {
  border-left: 1px solid gray;
  min-height: 500px;
  padding-left: 15px;
}
@media (max-width: 1023px) {
  .popup-border-left {
    border-left: none;
    height: auto;
    padding-left: 0;
  }
}

@media (min-width: 768px) {
  .set-instruction div {
    margin-top: 50px;
  }
}

/* @media (max-width: 1920px) {
  .set-instruction div {
    margin-top: 3%;
  }
} */

.flatpicker-filter {
  margin-right: 10px;
  border-radius: 5px !important;
  border-right: 1px solid !important;
}

/* .fc-day-today {
  background-color: red !important;
} */

/* .fc-day-past {
  background-color: red !important;
} */
